import AppModule from '@/models/AppModule';
import Permission from '@/models/Permission';
import { handleImportError, parseBoolProp, parseDateTimeProp, parseIntProp } from '../_helpers';

export default {
	path: '/Portal',
	component: () => import('./portal-imports').then(x => x._PortalApp).catch(handleImportError),
	meta: { blockOffline: true, appName: 'portal', requiresAuth: true, requiresCompanyUser: true, permissions: [Permission.companyOffice], lockOnCompanyChange: true, },
	children: [
		{
			path: '',
			component: () => import('./portal-imports').then(x => x.DashboardPage).catch(handleImportError),
			meta: { title: 'Dashboard', isHomePage: true, },
		},
		{
			path: 'Config',
			component: () => import('./portal-imports').then(x => x.HomePage).catch(handleImportError),
			meta: { title: 'Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Roles',
			component: () => import('./portal-imports').then(x => x.RolesPage).catch(handleImportError),
			meta: { title: 'Roles', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			name: 'Config-Roles-Edit',
			path: 'Config/Roles/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.RoleEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Role Edit', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Roles/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Config-Roles-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Config/Services',
			component: () => import('./portal-imports').then(x => x.ServicesPage).catch(handleImportError),
			meta: { title: 'Services', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			name: 'Config-Services-Edit',
			path: 'Config/Services/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.ServiceEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Service Edit', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/Services/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Config-Services-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Config/Inspections',
			component: () => import('./portal-imports').then(x => x.InspectionsPage).catch(handleImportError),
			meta: { title: 'Inspections', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/PaymentTerms',
			component: () => import('./portal-imports').then(x => x.PaymentTermsPage).catch(handleImportError),
			meta: { title: 'Payment Terms', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/PaymentSettings',
			component: () => import('./portal-imports').then(x => x.PaymentSettingsPage).catch(handleImportError),
			meta: { title: 'Payment Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/CompanySettings',
			component: () => import('./portal-imports').then(x => x.CompanySettingsPage).catch(handleImportError),
			meta: { title: 'Company Settings', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/TankTypes',
			component: () => import('./portal-imports').then(x => x.TankTypesPage).catch(handleImportError),
			meta: { title: 'Tank Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/VehicleMaintenanceTypes',
			component: () => import('./portal-imports').then(x => x.VehicleMaintenanceTypesPage).catch(handleImportError),
			meta: { title: 'Vehicle Maintenance Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/VehicleTypes',
			component: () => import('./portal-imports').then(x => x.VehicleTypesPage).catch(handleImportError),
			meta: { title: 'Vehicle Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/WasteDisposalSiteTypes',
			component: () => import('./portal-imports').then(x => x.WasteDisposalSiteTypesPage).catch(handleImportError),
			meta: { title: 'Waste Disposal Site Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/WasteTypes',
			component: () => import('./portal-imports').then(x => x.WasteTypesPage).catch(handleImportError),
			meta: { title: 'Waste Types', permissions: [Permission.companyOffice, Permission.companySettings], },
		},
		{
			path: 'Config/RentalItemTypes',
			component: () => import('./portal-imports').then(x => x.RentalItemTypePage).catch(handleImportError),
			meta: { title: 'Rental Item Types', permissions: [Permission.companyOffice, Permission.companySettings], modules: [AppModule.rentals], },
		},
		{
			path: 'RentalItems',
			component: () => import('./portal-imports').then(x => x.RentalItemsPage).catch(handleImportError),
			props: route => ({
				filter: route.query.filter,
			}),
			meta: { title: 'Rental Items', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'RentalItem-Details',
			path: 'RentalItems/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.RentalItemDetailsPage).catch(handleImportError),
			props: route => ({
				id: parseIntProp(route.params.id),
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Rental Item Details', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'RentalItem-Edit',
			path: 'RentalItems/:id(\\d+)/Edit',
			component: () => import('./portal-imports').then(x => x.RentalItemEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Rental Item Edit', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'CustomerRentals-Attention',
			component: () => import('./portal-imports').then(x => x.CustomerRentalAttentionPage).catch(handleImportError),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customer Rental Need Attention', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'CustomerRentals',
			component: () => import('./portal-imports').then(x => x.CustomerRentalsPage).catch(handleImportError),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				view: route.query.view,
				typeFilter: parseIntProp(route.query.typeFilter),
			}),
			meta: { title: 'Customer Rentals', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			name: 'CustomerRentals-Detail',
			path: 'CustomerRentals/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.CustomerRentalDetailPage).catch(handleImportError),
			props: route => ({
				id: parseIntProp(route.params.id),
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customer Rental Detail', permissions: [Permission.companyOffice, Permission.companyRentals], },
		},
		{
			path: 'Emails',
			component: () => import('./portal-imports').then(x => x.EmailsPage).catch(handleImportError),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Emails', permissions: [Permission.companyOffice], },
		},
		{
			path: 'Reports',
			component: () => import('./portal-imports').then(x => x.ReportsPage).catch(handleImportError),
			meta: { title: 'Reports', permissions: [Permission.companyOffice, Permission.companyReports], },
		},
		{
			path: 'Vehicles',
			component: () => import('./portal-imports').then(x => x.VehiclesPage).catch(handleImportError),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Vehicles', },
		},
		{
			name: 'Vehicles-Details',
			path: 'Vehicles/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.VehicleDetailsPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Vehicle Details', },
		},
		{
			path: 'Vehicles/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Vehicles-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Timesheets',
			component: () => import('./portal-imports').then(x => x.TimesheetsPage).catch(handleImportError),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Timesheets', permissions: [Permission.companyOffice, Permission.companyTimeSheets], },
		},
		{
			path: 'Timesheets/Punch',
			component: () => import('./portal-imports').then(x => x.TimesheetsPunchPage).catch(handleImportError),
			props: route => ({ referrer: route.query.referrer, }),
			meta: { title: 'Time Clock', },
		},
		{
			path: 'Users',
			component: () => import('./portal-imports').then(x => x.UsersPage).catch(handleImportError),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
			meta: { title: 'Users', permissions: [Permission.companyOffice, Permission.companyUsers], },
		},
		{
			name: 'Users-Details',
			path: 'Users/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.UserDetailsPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'User Details', permissions: [Permission.companyOffice, Permission.companyUsers], },
		},
		{
			path: 'Users/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Users-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Inventory',
			component: () => import('./portal-imports').then(x => x.InventoryPage).catch(handleImportError),
			props: route => ({
				search: route.query.search,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Inventory', },
		},
		{
			name: 'Inventory-Edit',
			path: 'Inventory/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.InventoryEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Inventory Edit', },
		},
		{
			path: 'Inventory/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Inventory-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WorkOrders/Estimates',
			component: () => import('./portal-imports').then(x => x.WorkOrders_EstimatesPage).catch(handleImportError),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Estimates', },
		},
		{
			path: 'WorkOrders/Invoices',
			component: () => import('./portal-imports').then(x => x.WorkOrders_InvoicesPage).catch(handleImportError),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Invoices', },
		},
		{
			path: 'WorkOrders/Incomplete',
			component: () => import('./portal-imports').then(x => x.WorkOrders_IncompleteWorkOrdersPage).catch(handleImportError),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Incomplete Service Orders', },
		},
		{
			path: 'WorkOrders/Dispatch',
			component: () => import('./portal-imports').then(x => x.WorkOrders_DispatchPage).catch(handleImportError),
			props: route => ({
				selectedDate: parseDateTimeProp(route.query.date),
				filter: parseIntProp(route.query.filter),
			}),
			meta: { title: 'Dispatch Calendar', },
		},
		{
			name: 'WorkOrders-Edit',
			path: 'WorkOrders/Edit/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.WorkOrders_WorkOrderEditPage).catch(handleImportError),
			props: route => ({
				id: parseIntProp(route.params.id),
				status: parseIntProp(route.query.status),
				customerId: parseIntProp(route.query.customerId),
				customerRentalId: parseIntProp(route.query.customerRentalId),
				customerLocationId: parseIntProp(route.query.customerLocationId),
				duplicate: parseBoolProp(route.query.duplicate),
				duplicateDate: parseDateTimeProp(route.query.duplicateDate),
			}),
			meta: { title: 'Edit Service Order', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Edit',
			redirect: to => {
				return {
					name: 'WorkOrders-Edit',
					params: {
						id: to.query.id || '0',
					},
					query: {
						status: to.query.status,
						customerId: to.query.customerId,
						customerLocationId: to.query.customerLocationId,
						duplicate: to.query.duplicate,
					}
				}
			},
		},
		{
			name: 'WorkOrders-Complete',
			path: 'WorkOrders/Complete/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.WorkOrders_WorkOrderCompletePage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Service Order Details', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Complete',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WorkOrders-Complete', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			name: 'WorkOrders-Details-Office',
			path: 'WorkOrders/Details/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.WorkOrders_WorkOrderDetailsPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Invoice Details', workOrderPage: true, },
		},
		{
			path: 'WorkOrders/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WorkOrders-Details-Office', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'Customers',
			component: () => import('./portal-imports').then(x => x.Customers_CustomersPage).catch(handleImportError),
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Customers', },
		},
		{
			path: 'Customers/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x._CustomerApp).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			children: [
				{
					path: '',
					component: () => import('./portal-imports').then(x => x.Customers_CustomerDetailsPage).catch(handleImportError),
					beforeEnter: to => {
						const path = to.path.endsWith('/') ? to.path : (to.path + '/');
						if (to.query.tab === 'workOrders' || to.query.tab === 'schedules') { return { path: path + 'WorkOrders', query: {} }; }
						if (to.query.tab === 'billing') { return { path: path + 'Billing', query: {} }; }
						if (to.query.tab === 'wasteDisposals') { return { path: path + 'WasteDisposals', query: {} }; }
					},
					props: route => ({
						id: parseIntProp(route.params.id),
					}),
					meta: { title: 'Customer Details', },
				},
				{
					path: 'WorkOrders',
					component: () => import('./portal-imports').then(x => x.Customers_CustomerWorkOrdersPage).catch(handleImportError),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Service Orders', },
				},
				{
					path: 'Rentals',
					component: () => import('./portal-imports').then(x => x.Customers_CustomerRentalsPage).catch(handleImportError),
					props: route => ({
						id: parseIntProp(route.params.id),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
						sort: route.query.sort,
					}),
					meta: { title: 'Customer Rentals', modules: [AppModule.rentals], },
				},
				{
					path: 'Billing',
					component: () => import('./portal-imports').then(x => x.Customers_CustomerBillingPage).catch(handleImportError),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Billing', },
				},
				{
					path: 'WasteDisposals',
					component: () => import('./portal-imports').then(x => x.Customers_CustomerWasteDisposalsPage).catch(handleImportError),
					props: route => ({
						id: parseIntProp(route.params.id),
						startDate: parseDateTimeProp(route.query.startDate),
						endDate: parseDateTimeProp(route.query.endDate),
						location: parseIntProp(route.query.location),
						pageNumber: parseIntProp(route.query.pageNumber),
						pageSize: parseIntProp(route.query.pageSize),
					}),
					meta: { title: 'Customer Waste Disposals', },
				},
			],
		},
		{
			path: 'Customers/Details',
			redirect: to => {
				if (parseInt(to.query.id) >= 0) {
					return { path: '/Portal/Customers/' + to.query.id, query: { tab: to.query.tab } };
				} else {
					return { path: '/404', query: {} };
				}
			},
		},
		{
			name: 'Customer-Edit',
			path: 'Customers/Edit/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.Customers_CustomerEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Customer Edit', },
		},
		{
			path: 'Customers/Edit',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'Customer-Edit', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WasteDisposal',
			component: () => import('./portal-imports').then(x => x.WasteDisposalsPage).catch(handleImportError),
			props: route => ({
				startDate: parseDateTimeProp(route.query.startDate),
				endDate: parseDateTimeProp(route.query.endDate),
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Waste Disposals', },
		},
		{
			name: 'WasteDisposal-Details',
			path: 'WasteDisposal/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.WasteDisposalDetailsPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Waste Disposal Details', },
		},
		{
			path: 'WasteDisposal/:id(\\d+)/Edit',
			component: () => import('./portal-imports').then(x => x.WasteDisposalCreatePage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Waste Disposal Edit', },
		},
		{
			path: 'WasteDisposal/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'WasteDisposal-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
		{
			path: 'WasteDisposal/Create',
			component: () => import('./portal-imports').then(x => x.WasteDisposalCreatePage).catch(handleImportError),
			props: () => ({ id: 0 }),
			meta: { title: 'Log Waste Disposal', },
		},
		{
			path: 'DisposalSites',
			component: () => import('./portal-imports').then(x => x.DisposalSitesPage).catch(handleImportError),
			props: route => ({
				capacityFilter: route.query.capacityFilter,
				activeFilter: route.query.activeFilter,
				landFilter: route.query.landFilter,
				seasonFilter: route.query.seasonFilter,
				sort: route.query.sort,
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Disposal Sites', },
		},
		{
			name: 'DisposalSites-Details',
			path: 'DisposalSites/:id(\\d+)',
			component: () => import('./portal-imports').then(x => x.DisposalSiteDetailsPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Disposal Site Details', },
		},
		{
			path: 'DisposalSites/Details',
			redirect: to => (parseInt(to.query.id) >= 0 ? { name: 'DisposalSites-Details', params: { id: to.query.id, }, query: {} } : { path: '/404', query: {} }),
		},
	]
};
